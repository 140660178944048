/* site variables + Materialize override variables */
@import "./base/variables";

/* external styles and fonts */
@import "../../node_modules/@fontsource/fira-sans/scss/mixins";
@import "../../node_modules/materialize-css/sass/materialize.scss";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/solid.scss";

/* site styles */
@import "./base/mixins";
@import "./base/utilities";
@import "./components/buttons";
@import "./base/typography";
@import "./layout/rounded-card";

// styles below override Materialize styles

html {
  background: top left / cover no-repeat #1e2339
    url("/images/main-background-gradient-mesh@3x.png");
}

// form overrides
input {
  color: $ownd-colors-white;
}
.input-field {
  & > label {
    font-size: 1.125rem;
    color: #bba7cc;
    &:not(.label-icon).active {
      color: $ownd-colors-purple;
    }
  }
}
