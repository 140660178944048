@import "../base/variables";

// override Materialize styles
.btn {
  width: 100%;
  background: linear-gradient(to right, #cb31e6, #8c2eff);
  &-floating {
    &:focus {
      background: linear-gradient(to right, #cb31e6, #8c2eff);
    }
  }
  &:focus {
    background: transparent;
  }
}

// Ownd styles
.btn {
  &--password {
    border-radius: 10px;
    background: transparent;
    border: none;
    outline: none;
    width: 44px;
    height: 44px;
    color: $ownd-colors-white;
    position: absolute;
    right: 0;
    box-shadow: none;
    &:hover,
    &:focus {
      background-color: transparent;
      box-shadow: none;
    }
    & > i {
      font-size: 1.14rem;
      color: $ownd-colors-gray;
    }
  }
  &--external-link {
    padding: 0;
    justify-content: space-between;
    &:focus,
    &:hover {
      background: transparent;
      border: none;
      box-shadow: none;
    }
    &,
    & > * {
      font-weight: normal;
      text-transform: none;
      display: flex;
      background: none;
      border: none;
      box-shadow: none;
      align-items: center;
      font-size: 1.28rem;
    }
    & img {
      width: 2rem;
      height: 2rem;
      margin-right: 1rem;
    }
    & i {
      font-size: 1.2rem;
    }
  }
}
