// Ownd variables
$ownd-colors-white: #ffffff;
$ownd-colors-purple: #bd88fc;
$ownd-colors-gray: #bba7cc;

// override Materialize typography
$font-stack: "Fira Sans", sans-serif;
$off-black: $ownd-colors-white;
$h1-fontsize: 1.5rem;

// override Materialize button variables
$button-radius: 10px;
$button-large-font-size: 1.28rem;

// forms
$input-border-color: $ownd-colors-white;
$input-focus-color: $ownd-colors-purple;
$input-font-size: 1.28rem;
$placeholder-text-color: $ownd-colors-gray;
$input-margin: 0 0 2rem 0;
