/* flexbox styles */
.flex {
  display: flex;
  &-column {
    flex-direction: column;
  }
}
.justify {
  &-between {
    justify-content: space-between;
  }
  &-end {
    justify-content: flex-end;
  }
}
.align-items {
  &-stretch {
    align-items: stretch;
  }
}

/* dimension styles */
.h {
  &-full {
    height: 100%;
  }
}

/* hide element to all except screen readers */
// https://gist.github.com/ffoodd/000b59f431e3e64e4ce1a24d5bb36034
.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important; /* 2 */
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important; /* 3 */
}
