// margin and padding utility classes
// https://medium.com/@jerrythimothy/margins-paddings-sass-mixin-16460c32507f

$max: 20;
$offset: 1;
$unit: "rem";
@mixin list-loop($className, $styleName) {
  $i: 0;
  @while $i <= $max {
    #{$className + $i} {
      #{$styleName}: #{$i + $unit};
    }
    $i: $i + $offset;
  }
}
// Margins
@include list-loop(".mt-", "margin-top");
@include list-loop(".me-", "margin-right");
@include list-loop(".mb-", "margin-bottom");
@include list-loop(".ms-", "margin-left");
@include list-loop(".m-", "margin");
// Paddings
@include list-loop(".pt-", "padding-top");
@include list-loop(".pe-", "padding-right");
@include list-loop(".pb-", "padding-bottom");
@include list-loop(".ps-", "padding-left");
@include list-loop(".p-", "padding");
